import * as Yup from 'yup';

import { IStatusColors } from 'components/Status';

import {
  WarehouseStatuses,
  DeliverooSiteStatus,
  IStoreDeliverooDetails,
  IDeliverooSiteSettings,
  CariSiteStatus,
  JahezSiteStatus
} from './types';
export {
  EP_WAREHOUSES_LIST,
  EP_WAREHOUSES_GET_BY_ID,
  EP_WAREHOUSES_UPDATE,
  EP_STORES_GET_DELIVEROO_SETTINGS,
  EP_STORES_UPDATE_DELIVEROO as EP_STORES_UPDATE_DELIVEROO_SETTINGS
} from 'modules/endpoints';

export const WarehouseStatusDetails: { [key in WarehouseStatuses]: { label: string; color: IStatusColors } } = {
  [WarehouseStatuses.Active]: { label: 'Active', color: 'success' },
  [WarehouseStatuses.Busy]: { label: 'Busy', color: 'warning' },
  [WarehouseStatuses.Inactive]: { label: 'Inactive', color: 'error' }
};

export const DeliverooSiteStatusDetails: { [key in DeliverooSiteStatus]: { label: string; color: IStatusColors } } = {
  [DeliverooSiteStatus.Open]: { label: 'Open', color: 'success' },
  [DeliverooSiteStatus.Closed]: { label: 'Closed', color: 'error' },
  [DeliverooSiteStatus.ReadyToOpen]: { label: 'Ready to open', color: 'warning' }
};

export const CariSiteStatusDetails: { [key in CariSiteStatus]: { label: string; color: IStatusColors } } = {
  [DeliverooSiteStatus.Open]: { label: 'Open', color: 'success' },
  [DeliverooSiteStatus.Closed]: { label: 'Closed', color: 'error' }
};

export const JahezSiteStatusDetails: { [key in JahezSiteStatus]: { label: string; color: IStatusColors } } = {
  [JahezSiteStatus.Open]: { label: 'Open', color: 'success' },
  [JahezSiteStatus.Closed]: { label: 'Closed', color: 'error' }
};

export const WarehouseUpdateScheme = Yup.object().shape({
  etaBufferTime: Yup.number().required('Start time is required').moreThan(-1),
  serviceHoursStartTime: Yup.string().required('Start time is required'),
  serviceHoursEndTime: Yup.string().required('End time is required'),
  busyMessageEnglish: Yup.string().when('status', {
    is: WarehouseStatuses.Busy,
    then: (schema) => schema.required('English Busy Message is required')
  }),
  busyMessageArabic: Yup.string().when('status', {
    is: WarehouseStatuses.Busy,
    then: (schema) => schema.required('Arabic Busy Message is required')
  })
});

export const DeliverooSettingsUpdateScheme = Yup.object().shape({
  deliverooServiceHoursStartTime: Yup.string().when('deliverooSiteId', {
    is: (deliverooSiteId: string) => !!deliverooSiteId && deliverooSiteId.length > 0,
    then: (schema) => schema.required('Start time is required')
  }),
  deliverooServiceHoursEndTime: Yup.string().when('deliverooSiteId', {
    is: (deliverooSiteId: string) => !!deliverooSiteId && deliverooSiteId.length > 0,
    then: (schema) => schema.required('End time is required')
  }),
  deliverooSiteStatus: Yup.string().when('deliverooSiteId', {
    is: (deliverooSiteId: string) => !!deliverooSiteId && deliverooSiteId.length > 0,
    then: (schema) => schema.required('Status is required')
  })
});

export const DeliverooSiteScheme = Yup.object().shape({
  deliverooSiteId: Yup.string().nullable()
});

export const DelivrooStoreDetailsToSiteSettings = (storeDetails: IStoreDeliverooDetails): IDeliverooSiteSettings => ({
  id: storeDetails.id,
  deliverooSiteId: storeDetails.deliverooSiteId
});
